import axios, { AxiosError, AxiosResponse } from 'axios';
import routes from './routes';
import getCurrentLanguage from '../functions/getCurrentLanguage';

const printInvoice = async (
    id: string | any,
    token: string,
    invoiceNo: string,
    foreign: boolean,
    proforma?: boolean,
    qr_code?: string | null,
): Promise<AxiosResponse> => {
    return new Promise((resolve, reject) => {
        try {
            const formData = new FormData();
            formData.append('token', token);
            if (qr_code && !foreign) {
                formData.append('qr_code', `data:image/jpeg;base64,${qr_code}`);
            }

            axios({
                url: proforma ? routes.proforma.downloadProforma(id) : routes.invoice.downloadInvoice(id),
                method: 'POST',
                responseType: 'blob',
                data: formData,
                headers: {
                    'Accept-Language': getCurrentLanguage(),
                    'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundaryggBjcnULJCwOMuKk',
                },
            })
                .then((response) => {
                    if (response.status === 200) {
                        resolve(response);
                    } else {
                        reject(new Error('Download failed.'));
                    }
                })
                .catch(async (error: AxiosError) => {
                    if (error.response?.data instanceof Blob) {
                        try {
                            const text = await error.response.data.text();
                            const parsed = JSON.parse(text);
                            reject(parsed); // throw only parsed error
                            return;
                        } catch (err) {
                            const fallbackText = await error.response.data.text();
                            reject({ message: fallbackText });
                            return;
                        }
                    }
                    reject(error);
                });
        } catch (error) {
            reject(error as AxiosError); // Reject the promise with the error if an exception occurs
        }
    });
};

export default printInvoice;
