import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import styled from 'styled-components';
import { useDetectClickOutside } from 'react-detect-click-outside';
import Dropdown from '../dropdown';
import { useUserDropdownList } from '../../constants/useUserDropdownList';
import { MQ_BREAKPOINTS, breakpoints } from '../../constants/breakpoints';
import useTheme from '../../hooks/useTheme';
import colors from '../../global/colors';
import useResponsive from '../../hooks/responsive/useResponsive';

export const UserSettingsController = () => {
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const isDarkTheme = useTheme();
    const { isTabletAndDown } = useResponsive();

    const closeDropdown = (): void => {
        setDropdownVisible(false);
    };

    const userDropdown = useUserDropdownList();

    const userDropdownItemsList = userDropdown.map((item) => {
        if (!item.handler) {
            return {
                ...item,
                handler: () => closeDropdown(),
            };
        } else {
            return {
                ...item,
                handler: () => {
                    item.handler && item.handler();
                    closeDropdown();
                },
            };
        }
    });

    const ref = useDetectClickOutside({ onTriggered: closeDropdown });

    return (
        <UserSettingsControllerContainer ref={ref}>
            <UserPlace
                onClick={() => {
                    setDropdownVisible(!dropdownVisible);
                }}
            >
                <FontAwesomeIcon
                    icon={faUser}
                    color={isDarkTheme ? 'var(--purple)' : !isTabletAndDown ? 'var(--purple)' : colors.white}
                    className="user-icon"
                />
            </UserPlace>
            {dropdownVisible && (
                <DropdownContainer>
                    <Dropdown arrowRight="4px" itemList={userDropdownItemsList} />
                </DropdownContainer>
            )}
        </UserSettingsControllerContainer>
    );
};

const UserSettingsControllerContainer = styled.div`
    position: relative;
`;

export const UserPlace = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--purple);
    cursor: pointer;
    .user-icon {
        height: 0.8em;
    }
    z-index: 9999;
`;

export const DropdownContainer = styled.div`
    top: calc(100% + 1.2rem);
    height: 312px;
    width: 300px;
    right: -10px;
    position: absolute;
    z-index: 9999;

    @media only screen and (max-width: ${breakpoints.tablet}px) {
        right: -10px;
        top: 36px;
    }

    @media only screen and (max-width: ${breakpoints.tablet - 1}px) {
        width: 100vw;
        right: -64px;
    }
    @media only screen and (max-width: ${breakpoints.mobileL}px) {
        right: -79px;
    }
    @media only screen and (max-width: ${breakpoints.mobileS}px) {
        right: -64px;
    }
    @media only screen and (max-width: ${breakpoints.mobileXS}px) {
        right: -63px;
    }
`;
